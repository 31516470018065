import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userpool from "../utils/userpool";
export const authenticate = (Email, Password) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Email,
      Pool: userpool,
    });
    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log("login done");
        resolve(result);
      },
      onFailure: (err) => {
        console.log("login failed", err);
        reject(err);
      },
    });
  });
};

export const logout = () => {
  const user = userpool.getCurrentUser();
  user.signout();
  window.location.href = "/";
};
